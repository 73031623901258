<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('university_courses')"
	                    :isNewButton="checkPermission('universitycourse_store')"
	                    :isColumns="true"
	                    @new-button-click="showModal('create')"
	                    @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
                </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('university_courses')"
		                      :isNewButton="checkPermission('universitycourse_store')"
		                      :isColumns="true"
		                      @new-button-click="showModal('create')"
		                      @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
                </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="number" v-model="datatable.queryParams.filter.id"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('code')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.code"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="5" lg="5" xl="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="5" lg="5" xl="4">
                        <b-form-group :label="$t('university_where_the_course_was_taken')">
                            <b-form-select v-model="datatable.queryParams.filter.university_id"
                                           :options="universities"
                            >
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('language')">
                            <parameter-selectbox code="languages" v-model="datatable.queryParams.filter.language"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="formModal" size="xl" :onHideOnlyX="true" :footer="true" :footer-class="'justify-content-center'">
                <template v-slot:CommonModalTitle>
                    <div>{{ formProcess=='new' ? $t('new').toUpper():$t('edit').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="form">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('course_name')">
                                            <b-form-input type="text"
                                                          v-model="form.name"
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="name_tr" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('course_name') + '('+$t('turkish')+')'">
                                            <b-form-input type="text"
                                                          v-model="form.name_tr"
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="code" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('course_code')">
                                            <b-form-input type="text"
                                                          v-model="form.code"
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-8">
                                    <ValidationProvider name="university_id" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('university_where_the_course_was_taken')">
                                            <b-form-select v-model="form.university_id"
                                                           :options="universities"
                                                           :class="errors[0] ? 'is-invalid':''"
                                            >
                                            </b-form-select>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="language" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('course_language')">
                                            <parameter-selectbox code="languages" v-model="form.language" :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
                <template v-slot:CommonModalFooter>
                    <b-form-group>
                        <b-button variant="primary" @click="formCreate" v-if="formProcess=='create'">{{ $t('save') }}</b-button>
                        <b-button variant="primary" @click="formUpdate" v-if="formProcess=='update'">{{ $t('update') }}</b-button>
                    </b-form-group>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import UniversityService from "@/services/UniversityService";
    import SemesterService from "@/services/SemesterService";
    import UniversityCourseService from "@/services/UniversityCourseService";
    import CommonModal from "@/components/elements/CommonModal";
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from 'qs'
    import ParameterSelectbox from "../../../components/interactive-fields/ParameterSelectbox";

    export default {
        components: {
            ParameterSelectbox,
            HeaderMobile,
            AppLayout,
            Header,
            DatatableFilter,
            Datatable,
            CommonModal,
            ValidationProvider,
            ValidationObserver,
        },
        metaInfo() {
            return {
                title: this.$t('university_courses')
            }
        },
        data() {
            return {
                universities: [],
                semesters: [],
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons:[
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "universitycourse_update",
                                    callback:(row)=>{
                                        this.showModal('update', row.id)
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "universitycourse_delete",
                                    callback:(row)=>{
                                        this.formDelete(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('id').toUpper(),
                            field: 'id',
                            hidden: true,
                        },
                        {
                            label: this.$t('code').toUpper(),
                            field: 'code',
                            hidden: false,
                        },
                        {
                            label: this.$t('name').toUpper(),
                            field: 'name',
                            html: true,
                            sortable: true,
                            formatFn:(col,row)=>{
                                let text ='';
                                text += '<strong>'+row.name+'</strong><br>';
                                text += row.name_tr+'<br>';
                                return text;
                            },
                            hidden: false,
                        },
                        {
                            label: this.$t('university_where_the_course_was_taken').toUpper(),
                            field: ('university_name'),
                            hidden: false,
                        },
                        {
                            label: this.$t('language').toUpper(),
                            field: 'language',
                            hidden: false,
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: '-id',
                        page: 1,
                        limit: 20
                    }
                },
                formProcess: null,
                formId: null,
                form: {},
            }
        },
        created() {
            this.filterSet()
            this.getUniversities();
            this.getSemesters();
            this.formClear();
        },
        methods: {
            filterSet(){
                this.datatable.queryParams.filter = {
                    id: null,
                    code: null,
                    name: null,
                    university_id: null,
                    language: null,
                };
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key){
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };
                return UniversityCourseService.getAll(config)
                                              .then((response) => {
                                                  this.datatable.rows = response.data.data
                                                  this.datatable.total = response.data.pagination.total
                                              })
                                              .finally(() => {
                                                  this.datatable.isLoading = false;
                                              });
            },
            // Modal
            showModal(process, id=null){
                this.formProcess = process
                this.formId = null;
                this.formClear();
                this.$refs.form.reset();
                if(this.formProcess=='update' && id){
                    UniversityCourseService.get(id)
                                           .then(response => {
                                               let data = response.data.data;
                                               this.form = {
                                                   university_id: data.university_id,
                                                   code:data.code,
                                                   name:data.name,
                                                   name_tr:data.name_tr,
                                                   language:data.language,
                                               }
                                               this.formId = id
                                           })
                                           .catch(e => {
                                               this.showErrors(e)
                                           })
                }
                this.$refs.formModal.$refs.commonModal.show();
            },
            // Clear
            formClear(){
                this.form ={
                    university_id:null,
                    code:null,
                    name:null,
                    name_tr:null,
                    language:null,
                }
            },
            // Create
            async formCreate(){
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    let formData = {
                        ...this.form
                    }
                    UniversityCourseService.store(formData)
                                           .then(response => {
                                               this.$toast.success(this.$t('api.' + response.data.message));
                                               this.getRows();
                                               this.formClear();
                                               this.$refs.form.reset();
                                               this.$refs.formModal.$refs.commonModal.hide()
                                           })
                                           .catch(e => {
                                               this.showErrors(e)
                                           })
                }
            },
            // Update
            async formUpdate(){
                const isValid = await this.$refs.form.validate();
                if (isValid && this.formId) {
                    let formData = {
                        ...this.form
                    }
                    UniversityCourseService.update(this.formId, formData)
                                           .then(response => {
                                               this.$toast.success(this.$t('api.' + response.data.message));
                                               this.getRows();
                                               this.formClear();
                                               this.$refs.form.reset();
                                               this.$refs.formModal.$refs.commonModal.hide()
                                           })
                                           .catch(e => {
                                               this.showErrors(e)
                                           })
                }
            },
            // Delete
            formDelete(id){
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        UniversityCourseService.del(id)
                                               .then(response => {
                                                   this.$toast.success(this.$t('api.' + response.data.message));
                                                   this.getRows();
                                                   this.formClear();
                                               })
                                               .catch(error => {
                                                   this.$toast.error(this.$t('api.' + error.data.message));
                                               });
                    }
                })
            },

            // Gets
            async getUniversities() {
                let config = {
                    params: {
                        sort: ('name'),
                        page: 1,
                        limit: 9999
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };
                return UniversityService.getAll(config)
                                        .then((response) => {
                                            this.universities = [{value: null, text: this.$t('select')}]
                                            const data = response.data.data;
                                            data.map(item => {
                                                this.universities.push({
                                                    value: item.id,
                                                    text: item.name,
                                                });
                                            });
                                        });
            },
            async getSemesters() {
                return SemesterService.semesters()
                                      .then((response) => {
                                          this.semesters = response.data.data;
                                      });
            },

            // Errors
            showErrors(e){
                if (e.status == '422') {
                    if (e.data.errors.name) {
                        this.$refs.form.errors.name.push(e.data.errors.name[0]);
                    }
                    if (e.data.errors.name_tr) {
                        this.$refs.form.errors.name_tr.push(e.data.errors.name_tr[0]);
                    }
                    if (e.data.errors.code) {
                        this.$refs.form.errors.code.push(e.data.errors.code[0]);
                    }
                    if (e.data.errors.university_id) {
                        this.$refs.form.errors.university_id.push(e.data.errors.university_id[0]);
                    }
                    if (e.data.errors.language) {
                        this.$refs.form.errors.language.push(e.data.errors.language[0]);
                    }
                }
                else if (e.status == '406') {
                    this.$toast.error(this.$t('api.' + e.data.message));
                }
            }
        }
    }
</script>

